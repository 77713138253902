import React, { useMemo, memo } from 'react';
import { Fade } from 'react-awesome-reveal';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, PERMISSIONS, TYPES, THEMES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';
const AcDownloadPileListTemplateModal = loadable(() =>
	import(
		'@molecules/ac-download-pilelist-template-modal/ac-download-pilelist-template-modal.web'
	)
);

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-empty-pilelist-block',
	CONTENT: 'ac-empty-pilelist-block__content',
	ICON: 'ac-empty-pilelist-block__icon',
	MESSAGE: 'ac-empty-pilelist-block__message',
};

const AcEmptyPilelistBlock = ({
	message = 'There are no piles imported in this pile list.',
	withMessage = false,
	withImport = false,
	download,
	callback,
}) => {
	const { can, cannot } = usePermissions();

	const getMessageClassNames = useMemo(() => {
		return clsx(_CLASSES.MESSAGE);
	}, []);

	const getIconClassNames = useMemo(() => {
		return clsx(_CLASSES.ICON);
	}, []);

	const getContentClassNames = useMemo(() => {
		return clsx(_CLASSES.CONTENT);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	const getDownloadPileListTemplateButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.OUTLINE,
			title: 'Download pile list template',
			callback: download,
			disabled: cannot(PERMISSIONS.PILE.READ),
			className: 'h-margin-x-0',
		};
	}, []);

	const getAddPilelistButtonOptions = useMemo(() => {
		return {
			rel: 'ac-add-button',
			type: TYPES.BUTTON,
			theme: THEMES.ALPHA,
			title: 'Add Pile list',
			disabled: cannot(PERMISSIONS.PILE.CREATE),
			callback: callback,
		};
	}, [callback]);

	return (
		<Fade duration={200} appear>
			<div className={getMainClassNames}>
				<div className={getContentClassNames}>
					{withMessage && (
						<AcRow>
							<AcColumn
								sm={{ size: 6, offset: 3 }}
								className={'h-text--align-center'}
							>
								<p
									className={getMessageClassNames}
									dangerouslySetInnerHTML={{
										__html: message,
									}}
								/>
							</AcColumn>
						</AcRow>
					)}

					{(AcIsSet(download) || AcIsSet(callback)) && (
						<AcContainer className={'h-padding-y-20'}>
							<AcRow>
								<AcColumn sm={6} className={'h-text--align-right'}>
									<AcButton
										{...getDownloadPileListTemplateButtonOptions}
										download="Download Pile list template"
									>
										<AcIcon icon={ICONS.FILE_DOWNLOAD_OUTLINE} />
										<span>Download template</span>
									</AcButton>
								</AcColumn>

								<AcColumn sm={6} className={'h-text--align-left'}>
									<AcButton {...getAddPilelistButtonOptions}>
										<AcIcon
											icon={
												withImport
													? ICONS.TEXT_BOX_PLUS_VARIANT_OUTLINE
													: ICONS.PLUS
											}
										/>
										<span>{`${
											withImport ? 'Import Pile List' : 'Add Pile List'
										}`}</span>
									</AcButton>
								</AcColumn>
							</AcRow>
						</AcContainer>
					)}
				</div>
			</div>
		</Fade>
	);
};

export default memo(AcEmptyPilelistBlock);
