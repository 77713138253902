// Imports => React
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  memo,
} from 'react';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import { TYPES } from '@constants';

// Imports => Utilities
import {
  AcUUID,
  AcIsSet,
  AcIsNull,
  AcIsUndefined,
  AcIsBoolean,
  AcIsEmptyString,
} from '@utils';

const _CLASSES = {
  MAIN: 'ac-select-box',
  NAVIGATOR: 'ac-select-box--navigator',
  HAS_VALUE: 'ac-select-box--selected',
  INPUT: {
    MAIN: 'ac-select-box__input',
    LABEL: 'ac-select-box__input-label',
    LABEL_EMPTY: 'ac-select-box__input-label--empty',
  },
  DISABLED: 'ac-select-box--disabled',
  LABEL: 'ac-select-box__label',
  OPEN: 'ac-select-box--open',
  ERROR: 'ac-select-box--error',
  LIST: {
    WRP: 'ac-select-box__list-wrp',
    MAIN: 'ac-select-box__list',
    ITEM: 'ac-select-box__list__item',
    ITEM_STATIC: 'ac-select-box__list__item--static',
    ITEM_SELECTED: 'ac-select-box__list__item--selected',
    ITEM_NULL_VALUE: 'ac-select-box__list__item--null-value',
    ITEM_NO_RESULTS: 'ac-select-box__list__item--no-results',
  },
  SEARCH: {
    INPUT: 'ac-select-box__search-input',
  },
  VALIDATION: {
    ERROR: 'ac-select-box__error',
  },
  INSTRUCTIONS: 'ac-select-box__instructions',
};

let _tryAndFindDelay = null;
let _tryAndFindSequence = '';

// Component
const AcSelectBox = ({
  value,
  label = 'Selectbox',
  name = 'selectbox',
  placeholder = 'Select an option',
  instructions,
  disabled = false,
  required = false,
  is_navigator = false,
  type = TYPES.SELECT,
  options = [],
  callback,
  validation,
  maxOptions = 8,
  className,
  reference = AcUUID(),
}) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [error, setError] = useState(null);
  const [height, setHeight] = useState(0);

  const $selectbox = useRef(null);
  const $label = useRef(null);
  const $input = useRef(null);
  const $list = useRef(null);
  const $searchinput = useRef(null);

  const getErrorClassNames = useMemo(() => {
    return clsx(_CLASSES.VALIDATION.ERROR);
  }, []);

  const getInstructionsClassNames = useMemo(() => {
    return clsx(_CLASSES.INSTRUCTIONS);
  }, []);

  const getSearchInputClassNames = useMemo(() => {
    return clsx(_CLASSES.SEARCH.INPUT);
  }, []);

  const getListItemClassNames = (itemValue, noresults = false) => {
    const isSelectedValue = itemValue === value;
    const isNullValue = AcIsNull(itemValue);
    return clsx(
      _CLASSES.LIST.ITEM,
      isSelectedValue && _CLASSES.LIST.ITEM_SELECTED,
      isNullValue && _CLASSES.LIST.ITEM_NULL_VALUE,
      noresults && _CLASSES.LIST.ITEM_NO_RESULTS
    );
  };

  const getListClassNames = useMemo(() => {
    return clsx(_CLASSES.LIST.MAIN);
  }, []);

  const getListWrpClassNames = useMemo(() => {
    return clsx(_CLASSES.LIST.WRP);
  }, []);

  const getLabelClassNames = useMemo(() => {
    return clsx(_CLASSES.LABEL);
  }, []);

  const getInputLabelClassNames = useMemo(() => {
    const empty = AcIsUndefined(value) || AcIsEmptyString(value);
    return clsx(_CLASSES.INPUT.LABEL, empty && _CLASSES.INPUT.LABEL_EMPTY);
  }, [value]);

  const getBoxLabelClassNames = useMemo(() => {
    return clsx(_CLASSES.INPUT.MAIN);
  }, []);

  const getMainClassNames = useMemo(() => {
    return clsx(
      _CLASSES.MAIN,
      disabled && _CLASSES.DISABLED,
      open && _CLASSES.OPEN,
      error && _CLASSES.ERROR,
      placeholder && _CLASSES.PLACEHOLDER,
      value && _CLASSES.HAS_VALUE,
      is_navigator && _CLASSES.NAVIGATOR,
      className
    );
  }, [value, disabled, error, open, placeholder, is_navigator, className]);

  useEffect(() => {
    removeEvents().then(() => {
      addEvents();

      setTimeout(() => {
        if (open === true) {
          if ($searchinput?.focus) {
            $searchinput?.focus();
          }
          // const $firstoption = $list.current.querySelector('li');
          // if ($firstoption & $firstoption.focus) {
          //   // $firstoption.focus();
          // }
        }
      }, 100);
    });

    return () => removeEvents();
  }, [open]);

  const addEvents = () => {
    document.addEventListener('keyup', handleKeyUp, { passive: true });
    document.addEventListener('click', hide, false);
  };

  const removeEvents = () => {
    return new Promise((resolve) => {
      document.removeEventListener('keyup', handleKeyUp, { passive: true });
      document.removeEventListener('click', hide, false);
      resolve();
    });
  };

  const handleQueryChange = (event) => {
    if (event?.preventDefault) event?.preventDefault();
    if (event?.persist) event?.persist();

    const value = event.target.value;

    setQuery(value);
  };

  const handleScrollListToOption = (item) => {
    if (AcIsSet(item)) {
      window.requestAnimationFrame(() => {
        const pos = item.offsetTop;
        if (AcIsSet(pos) && $list.current) {
          $list.current.scrollTop = pos - 20;
        }
      });
    }
  };

  const handleTryAndFocusOption = (key) => {
    if ($list && $list.current) {
      const $activeElement = document.activeElement;

      if (
        AcIsSet($activeElement) &&
        $activeElement.tagName.toLowerCase() !== 'li'
      ) {
        // no focus yet, let's focus on the first options
        const $firstoption = $list.current.querySelector('li');
        if ($firstoption?.focus) {
          $firstoption.focus();
          handleScrollListToOption($firstoption);
        }
      } else if (key === 38 || key === 'ArrowUp') {
        // prev option
        const $prev = $activeElement.previousElementSibling;

        if (AcIsSet($prev) && $prev?.focus) {
          $prev.focus();
          handleScrollListToOption($prev);
        }
      } else if (key === 40 || key === 'ArrowDown') {
        // next option
        const $next = $activeElement.nextElementSibling;

        if (AcIsSet($next) && $next?.focus) {
          $next.focus();
          handleScrollListToOption($next);
        }
      }
    }
  };

  const handleTryAndFindMatchingOption = (key, num) => {
    if (
      !(
        (num > 64 && num < 91) ||
        (num > 47 && num < 58) ||
        (num > 95 && num < 112) ||
        key === ' '
      )
    )
      return;

    if (_tryAndFindDelay) clearTimeout(_tryAndFindDelay);

    _tryAndFindSequence += key;

    if ($list.current && $list.current) {
      const $options = $list.current.querySelectorAll('li');

      if (AcIsSet($options)) {
        const len = $options.length;
        let n = 0;
        let found = null;

        for (n; n < len; n++) {
          const $item = $options[n];
          let text = $item.textContent;

          if (AcIsSet(text)) {
            text = text.toLowerCase();
            const query = _tryAndFindSequence.toLowerCase();

            if (text.indexOf(query) > -1) {
              if ($item.focus) $item.focus();
              found = $item;
              break;
            }
          }
        }

        handleScrollListToOption(found);
      }
    }

    _tryAndFindDelay = setTimeout(() => {
      _tryAndFindSequence = '';
    }, 600);
  };

  const handleKeyUp = (event) => {
    if (event && event.persist) event.persist();

    let inside = false;

    if ($selectbox && $selectbox.current) {
      inside = $selectbox.current.contains(event.target);
    }

    if (inside) {
      const key = event.key || event.which;

      const spacebarAndNotOpen =
        (key === 32 || key === 'Spacebar' || key === ' ') && !open;

      const $activeElement = document.activeElement;
      const enterAndNotOpen = (key === 13 || key === 'Enter') && !open;

      if ($activeElement.tagName.toLowerCase() === 'input') {
        return;
      }

      if (spacebarAndNotOpen || enterAndNotOpen) {
        if (
          AcIsSet($activeElement) &&
          $activeElement.tagName.toLowerCase() !== 'li' &&
          $activeElement.tagName.toLowerCase() !== 'input'
        )
          handleToggle(true);
      }

      if (key) {
        switch (key) {
          case 'Escape':
          case 27:
            handleToggle(false);
            break;

          case 38:
          case 'ArrowUp':
          case 40:
          case 'ArrowDown':
            handleTryAndFocusOption(key);
            break;

          default:
            handleTryAndFindMatchingOption(key, event.which);
        }
      }
    }
  };

  const hide = (event) => {
    if (event?.persist) event.persist();
    if (event?.target) {
      const $element = $selectbox.current;

      if ($element) {
        const inside = $element.contains(event.target);

        if (!inside) {
          handleToggle(false);
        }
      }
    }
  };

  const handleToggle = (state) => {
    if (state?.persist) state.persist();
    if (state?.stopPropagation) state.stopPropagation();
    if (state?.preventDefault) state.preventDefault();

    const options = parsedOptionsList;
    if (disabled || options.length === 0) return;

    const status = state === false || state === true ? state : !open;

    setOpen(status);
  };

  const handleClick = (event, item) => {
    if (event?.persist) event.persist();
    if (event?.stopPropagation) event.stopPropagation();

    const value = item.value;
    let err = false;

    if (validation) {
      err = validation(name, value, required, type);
      setError(err);
    }

    handleCallback(event, name, value, type);

    setTimeout(() => {
      window.requestAnimationFrame(() => {
        setOpen(false);
      });
    }, 200);
  };

  const handleCallback = (event, name, value, type) => {
    if (callback) callback(event, name, value, type);
  };

  const parsedOptionsList = useMemo(() => {
    const collection = options;
    const len = collection.length;
    let n = 0;
    let result = [];
    let substring = null;

    if (AcIsSet(query) && query?.length) {
      substring = query.toLowerCase();
    }

    for (n; n < len; n++) {
      const option = collection[n];
      const string = option?.name?.toLowerCase();

      // if (option.value === value) continue;
      if (substring && string.indexOf(substring) === -1) continue;

      result.push(option);
    }

    return result;
  }, [options, value, query]);

  const getSelectedOption = useMemo(() => {
    const collection = parsedOptionsList;
    const len = collection.length;
    let n = 0;
    let result = null;

    for (n; n < len; n++) {
      const option = collection[n];

      if (
        option.value === value ||
        parseInt(option.value, 10) === parseInt(value, 10)
      ) {
        result = option;
        break;
      }
    }

    return result;
  }, [value, parsedOptionsList, query]);

  const getSelectedLabel = useMemo(() => {
    if (AcIsUndefined(value) || AcIsEmptyString(value)) return placeholder;
    if (!AcIsSet(getSelectedOption)) return placeholder;
    if (is_navigator) return placeholder;

    return getSelectedOption && getSelectedOption.name;
  }, [getSelectedOption, placeholder, is_navigator, query]);

  const renderOptions = useMemo(() => {
    const collection = parsedOptionsList;
    const len = collection.length;
    let n = 0;
    let result = [];
    let names = [];

    if (len === 0) {
      result.push(
        <li
          key={`select-box-${name}-item-no-results`}
          className={getListItemClassNames(null, true)}
          role={'option'}
          tabIndex={'-1'}
          dangerouslySetInnerHTML={{
            __html: 'No results available',
          }}
        />
      );
    } else {
      for (n; n < len; n++) {
        const item = collection[n];

        const object = (
          <li
            key={`select-box-${name}-item-${item.value}`}
            onKeyUp={(event) => {
              if (!AcIsSet(event) || !AcIsSet(event.key)) return;
              if (event.key === 13 || event.key === 'Enter')
                handleClick(event, item);
            }}
            onClick={(event) => handleClick(event, item)}
            className={getListItemClassNames(item.value)}
            role={'option'}
            tabIndex={'-1'}
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        );

        result.push(object);
        names.push(item.name);
      }
    }

    return result;
  }, [parsedOptionsList, value, name, query]);

  const renderLabel = useMemo(() => {
    return (
      <div
        id={`label-${reference}`}
        className={getLabelClassNames}
        tabIndex={0}
        dangerouslySetInnerHTML={{
          __html: label,
        }}
      />
    );
  }, [label, query]);

  const renderError = useMemo(() => {
    return (
      <div
        className={getErrorClassNames}
        dangerouslySetInnerHTML={{
          __html: error,
        }}
      />
    );
  }, [error]);

  const renderInstructions = useMemo(() => {
    return (
      <div
        className={getInstructionsClassNames}
        dangerouslySetInnerHTML={{
          __html: instructions,
        }}
      />
    );
  }, [instructions]);

  const calculatedListHeight = useMemo(() => {
    const collection = parsedOptionsList;
    const len = collection.length;
    const available = Math.min(len, maxOptions);

    let height = len === 0 ? 4 * 37 : available * 40;

    // if ($list.current && $list.current.childNodes) {
    //   const children = Array.prototype.slice.call($list.current.childNodes);
    //   children
    //     .slice(0, available)
    //     .forEach((node) => (height += node.scrollHeight));
    // }

    height = height + (44 + 16); // search input height

    let result = height > 0 ? height / 10 + 0.1 : height;

    return result;
  }, [parsedOptionsList, maxOptions, open, query]);

  const getListInlineStyles = useMemo(() => {
    return { height: open ? `${calculatedListHeight}rem` : '0rem' };
  }, [calculatedListHeight, parsedOptionsList, open, query]);

  return (
    <div
      ref={$selectbox}
      aria-roledescription={'input'}
      aria-labelledby={`label-${reference}`}
      className={getMainClassNames}
      disabled={disabled}
      onClick={handleToggle}
      aria-hidden={!open}
    >
      <div
        htmlFor={reference}
        tabIndex={0}
        ref={$label}
        className={getBoxLabelClassNames}
      >
        <input
          type={TYPES.TEXT}
          name={name}
          id={reference}
          defaultValue={value}
          disabled={disabled}
          tabIndex={0}
        />
        <div className={getInputLabelClassNames}>
          <Fade duration={200} key={`select-box-value-${getSelectedLabel}`}>
            <span
              dangerouslySetInnerHTML={{
                __html: getSelectedLabel,
              }}
            />
          </Fade>
        </div>
        <div
          className={getListWrpClassNames}
          style={getListInlineStyles}
          role={'listbox'}
          tabIndex={'-1'}
        >
          <input
            ref={$searchinput}
            type={TYPES.TEXT}
            name={'query'}
            onInput={handleQueryChange}
            className={getSearchInputClassNames}
            onClick={(event) => {
              if (event?.persist) event.persist();
              if (event?.preventDefault) event.preventDefault();
              if (event?.stopPropagation) event.stopPropagation();
            }}
            autofocus={true}
          />
          <ul ref={$list} className={getListClassNames}>
            {renderOptions}
          </ul>
        </div>
      </div>
      {renderLabel}
      {error && renderError}
      {!error && instructions && renderInstructions}
    </div>
  );
};

export default memo(AcSelectBox);
