// Imports => React
import React, { useMemo } from 'react';
import clsx from 'clsx';

// Imports => Utilities
import { AcIsSet } from '@utils';

const _CLASSES = {
	MAIN: 'ac-divider',
	DEFAULT: 'ac-divider--default',
	WHITE: 'ac-divider--white',
	OFFWHITE: 'ac-divider--offwhite',
	EGGWHITE: 'ac-divider--eggwhite',
	LIGHT: 'ac-divider--light',
	SUBTLE: 'ac-divider--subtle',
	MEDIUM: 'ac-divider--medium',
	DARK: 'ac-divider--dark',
	PITCH: 'ac-divider--pitch',
	ALPHA: 'ac-divider--alpha',
	ALPHA_LIGHT: 'ac-divider--alpha-light',
	OMEGA: 'ac-divider--omega',
};

const AcDivider = ({ theme = 'default', label = null, className }) => {
	const getStyleClassNames = useMemo(() => {
		return clsx(
			_CLASSES.MAIN,
			theme && _CLASSES[theme.toUpperCase()],
			className
		);
	}, [theme, className]);

	return AcIsSet(label) ? (
		<div className={getStyleClassNames}>
			<span>{label}</span>
		</div>
	) : (
		<div className={getStyleClassNames} />
	);
};

export default React.memo(AcDivider);
