import React, { useMemo, memo } from 'react';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import { ICONS } from '@constants';

// Imports => Utilities
import { AcUUID } from '@utils';

// Imports => Atoms
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web'));
const AcRichContent = loadable(() =>
	import('@atoms/ac-rich-content/ac-rich-content.web')
);

const _CLASSES = {
	MAIN: 'ac-alert',
	DARKMODE: 'ac-alert--dark-mode',
	THEMES: {
		DEFAULT: 'ac-alert--default',
		ALPHA: 'ac-alert--alpha',
		ALPHA_LIGHT: 'ac-alert--alpha-light',
		OMEGA: 'ac-alert--omega',
		OMEGA_LIGHT: 'ac-alert--omega-light',
		WHITE: 'ac-alert--white',
		LIGHT: 'ac-alert--light',
		SUBTLE: 'ac-alert--subtle',
		MEDIUM: 'ac-alert--medium',
		DARK: 'ac-alert--dark',
		PITCH: 'ac-alert--pitch',
		BLACK: 'ac-alert--black',
		SUCCESS: 'ac-alert--success',
		ERROR: 'ac-alert--error',
		WARNING: 'ac-alert--warning',
		INFO: 'ac-alert--info',
		INFO_LIGHT: 'ac-alert--info-light',
	},
};

const AcAlert = memo(
	({
		id = AcUUID(),
		theme = 'default',
		darkmode = false,
		content,
		className,
	}) => {
		const getMainClassNames = useMemo(() => {
			return clsx(
				_CLASSES.MAIN,
				theme && _CLASSES.THEMES[theme.toUpperCase().replace(/-/g, '_')],
				darkmode && _CLASSES.DARKMODE,
				className
			);
		}, [theme, darkmode, className]);

		const renderIcon = useMemo(() => {
			let result = null;

			switch (theme) {
				case 'success':
					result = ICONS.CHECK_ALL;
					break;

				case 'info':
					result = ICONS.INFORMATION_OUTLINE;
					break;

				case 'warning':
					result = ICONS.ALERT_OCTAGRAM_OUTLINE;
					break;

				case 'error':
					result = ICONS.ALERT_CIRCLE_OUTLINE;
					break;
			}

			return result;
		}, [theme]);

		return (
			<div id={id} className={getMainClassNames} role={'alert'}>
				{renderIcon && <AcIcon icon={renderIcon} />}
				<AcRichContent content={`<p>${content}</p>`} />
			</div>
		);
	}
);

export { AcAlert };
export default AcAlert;
