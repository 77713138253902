import React, { useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { THEMES, SIZES } from '@constants';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

const _CLASSES = {
	MAIN: 'ac-tag',
	DISABLED: 'ac-tag--disabled',
	ACTIVE: 'ac-tag--active',
	THEME: {
		DEFAULT: 'ac-tag--default',
		PITCH: 'ac-tag--pitch',
		WHITE: 'ac-tag--white',
		SUBTLE: 'ac-tag--subtle',
		ALPHA: 'ac-tag--alpha',
		OMEGA: 'ac-tag--omega',
	},
};

const AcTag = ({
	id,
	label,
	theme = 'default',
	disabled,
	callback,
	active,
	className,
}) => {
	const handleCallback = (event) => {
		if (event && event.persist) event.persist();
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		if (disabled) return;
		if (callback) callback(id);
	};

	const getRippleTheme = useMemo(() => {
		if (theme === 'outline') {
			return theme;
		} else {
			return 'default';
		}
	}, [theme]);

	const getMainClassNames = useMemo(() => {
		const match = {
			theme: theme
				? theme.replace('-', '').replace('_', '').trim().toUpperCase()
				: false,
		};
		return clsx(
			_CLASSES.MAIN,
			disabled && _CLASSES.DISABLED,
			active && _CLASSES.ACTIVE,
			match?.theme && _CLASSES.THEME[match?.theme],
			className
		);
	}, [disabled, active, theme, className]);

	return (
		<div
			disabled={disabled}
			className={getMainClassNames}
			onClick={handleCallback}
		>
			<span
				dangerouslySetInnerHTML={{
					__html: label,
				}}
			/>
			<AcRipple theme={getRippleTheme} size={SIZES.SMALL} simple />
		</div>
	);
};

export default memo(AcTag);
